// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-join-arekkz-js": () => import("/opt/build/repo/src/pages/join-arekkz.js" /* webpackChunkName: "component---src-pages-join-arekkz-js" */),
  "component---src-pages-join-outside-xbox-js": () => import("/opt/build/repo/src/pages/join-outside-xbox.js" /* webpackChunkName: "component---src-pages-join-outside-xbox-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/opt/build/repo/.cache/data.json")

